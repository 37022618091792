import { type MouseEventHandler } from 'react';

import {
  BaseModal,
  Button,
  type IBaseModalProps,
} from '@gbs-monorepo-packages/common';

import { ButtonsContainer, TextContent, Title } from './styles';

export interface IDialogModalProps extends Partial<IBaseModalProps> {
  acceptText?: string;
  dataCy?: string;
  declineText?: string;
  disabled?: boolean;
  loading?: boolean;
  mainText: string;
  onAccept?: MouseEventHandler<HTMLButtonElement>;
  onDecline?: MouseEventHandler<HTMLButtonElement>;
}

export const DialogModal = ({
  acceptText,
  children,
  dataCy,
  declineText,
  disabled = false,
  loading = false,
  mainText,
  onAccept,
  onDecline,
  ...props
}: IDialogModalProps): JSX.Element => (
  <BaseModal loading={loading} dataCy={dataCy} {...props}>
    <TextContent data-cy="text-container">
      <Title data-cy={dataCy}>{mainText}</Title>
    </TextContent>

    {children}

    <ButtonsContainer>
      {declineText && (
        <Button
          type="button"
          dataCy="button-cancel"
          styleType="outline"
          onClick={onDecline}
          disabled={loading}
        >
          {declineText}
        </Button>
      )}
      {acceptText && (
        <Button
          type="button"
          dataCy="button-add"
          onClick={onAccept}
          loading={loading}
          disabled={disabled}
        >
          {acceptText}
        </Button>
      )}
    </ButtonsContainer>
  </BaseModal>
);
