import {
  type ButtonHTMLAttributes,
  type ReactElement,
  type ReactNode,
  forwardRef,
  useState,
} from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import {
  DropdownItem,
  DropdownMenuContent,
  OptionsButton,
  OptionsIcon,
  StyledArrow,
  TooltipArrow,
  TooltipContent,
} from './styles';

export interface IBaseDropdownProps
  extends DropdownMenuPrimitive.DropdownMenuProps {
  children: ReactNode;
  customTrigger?: ReactElement<HTMLButtonElement> | null;
  dataCy?: string;
  disabled?: boolean;
  zIndex?: number;
}

export interface IBaseTriggerDropdownProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const BaseTriggerDropdown = forwardRef<
  HTMLButtonElement,
  IBaseTriggerDropdownProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseTriggerDropdown({ ...props }, ref): JSX.Element {
  return (
    <OptionsButton ref={ref} aria-label="More options" {...props}>
      <OptionsIcon />
    </OptionsButton>
  );
});

export const BaseDropdown = ({
  children,
  customTrigger = null,
  dataCy = 'dropdown-menu-button',
  disabled = false,
  zIndex,
  ...props
}: IBaseDropdownProps): JSX.Element => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <DropdownMenuPrimitive.Root
      {...props}
      onOpenChange={(isOpen) => {
        setIsTooltipVisible(false);
        props.onOpenChange?.(isOpen);
      }}
    >
      <BaseDropdownTooltip message="Options" isVisible={isTooltipVisible}>
        <DropdownMenuPrimitive.Trigger
          disabled={disabled}
          asChild
          data-cy={dataCy}
          onMouseEnter={() => {
            setIsTooltipVisible(true);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        >
          {customTrigger ?? <BaseTriggerDropdown />}
        </DropdownMenuPrimitive.Trigger>
      </BaseDropdownTooltip>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          $zIndex={zIndex}
          data-cy="dropdown-menu-content"
          sideOffset={5}
        >
          {children}
          <StyledArrow />
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
};

export interface IBaseDropdownItemProps
  extends DropdownMenuPrimitive.DropdownMenuItemProps {
  type?: 'default' | 'large';
}

export const BaseDropdownItem = forwardRef<
  HTMLDivElement,
  IBaseDropdownItemProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseDropdownItem({ type = 'default', ...props }, ref): JSX.Element {
  return <DropdownItem ref={ref} {...props} $type={type} />;
});

export interface IBaseDropdownTooltipProps
  extends TooltipPrimitive.TooltipProviderProps {
  children: ReactNode;
  message?: string;
  dataCy?: string;
  isVisible?: boolean;
}

export const BaseDropdownTooltip = ({
  children,
  message,
  isVisible = false,
  dataCy = 'tooltip-field',
  ...props
}: IBaseDropdownTooltipProps): JSX.Element => {
  return (
    <TooltipPrimitive.Provider {...props}>
      <TooltipPrimitive.Root open={isVisible} delayDuration={300}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent sideOffset={5} data-cy={dataCy}>
            {message}
            <TooltipArrow />
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
