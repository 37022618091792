import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  & button + button {
    margin-left: 1rem;
  }
`;

export const TextContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 0 0 0.875rem;
`;

export const Title = styled.h1`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
`;
