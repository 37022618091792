import {
  type CanvasSpotProps,
  type CanvasSpotType,
  type Component,
  type Editor,
} from 'grapesjs';

import {
  CELL_TABLE_LAYOUT_SPOT_TYPE,
  CELL_TABLE_LAYOUT_TYPE,
  ROW_TABLE_LAYOUT_SPOT_TYPE,
  ROW_TABLE_LAYOUT_TYPE,
} from '../../../../constants/grapes';
import { getByCustomType } from '../../../../utils/grapes';
import { type IBlockContent } from '../../interfaces';
import { DefaultTableLayout } from './components/DefaultTable';

export const tableLayoutId = 'table-layout';
export const tableLayoutType = 'table-layout';

export const tableLayoutRowId = 'table-layout-row';
export const tableLayoutRowType = 'table-layout-row';

export const tableLayoutCellId = 'table-layout-cell';
export const tableLayoutCellType = 'table-layout-cell';

type ISpot = Omit<CanvasSpotProps<CanvasSpotType>, 'id'>;
interface IGenerateSpotConfig {
  type: CanvasSpotType;
  componentTypes: string[];
  checkParents?: boolean;
}

const generateSpot = (
  component: Component,
  { type, componentTypes, checkParents = true }: IGenerateSpotConfig
): ISpot => {
  const newSpot: ISpot = { type };
  const componentToSpot = getByCustomType(component, {
    componentTypes,
    checkParents,
  });

  if (!componentToSpot) return newSpot;

  newSpot.component = componentToSpot;
  return newSpot;
};

const TableLayoutPlugin = (editor: Editor, visible = true): void => {
  const { Canvas } = editor;

  const addTableLayoutCustomSpots = () => {
    Canvas.removeSpots({ type: ROW_TABLE_LAYOUT_SPOT_TYPE });
    Canvas.removeSpots({ type: CELL_TABLE_LAYOUT_SPOT_TYPE });

    const component = editor.getSelected();

    if (component) {
      const newSpotRow = generateSpot(component, {
        type: ROW_TABLE_LAYOUT_SPOT_TYPE,
        componentTypes: ROW_TABLE_LAYOUT_TYPE,
        checkParents: false,
      });
      if (newSpotRow.component) {
        Canvas.addSpot(newSpotRow);
        return;
      }

      const newSpotCell = generateSpot(component, {
        type: CELL_TABLE_LAYOUT_SPOT_TYPE,
        componentTypes: CELL_TABLE_LAYOUT_TYPE,
      });

      if (newSpotCell.component) {
        Canvas.addSpot(newSpotCell);
      }
    }
  };

  editor.on('component:toggled', addTableLayoutCustomSpots);

  editor.DomComponents.addType(tableLayoutCellType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value === tableLayoutCellType,
    model: {
      defaults: {
        attributes: {
          'custom-type': tableLayoutCellType,
        },
        draggable: tableLayoutRowType,
        name: 'Cell Layout',
        tagName: 'div',
      },
    },
  });

  editor.DomComponents.addType(tableLayoutRowType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value === tableLayoutRowType,
    model: {
      defaults: {
        attributes: {
          'custom-type': tableLayoutRowType,
        },
        droppable: tableLayoutCellType,
        draggable: tableLayoutType,
        name: 'Row Layout',
        tagName: 'div',
      },
    },
  });

  editor.DomComponents.addType(tableLayoutType, {
    extend: 'default',
    isComponent: (el) =>
      el.attributes?.getNamedItem('custom-type')?.value === tableLayoutType,
    model: {
      defaults: {
        attributes: {
          'custom-type': tableLayoutType,
        },
        droppable: tableLayoutRowType,
        tagName: 'div',
        name: 'Table Layout',
      },
    },
  });

  const tableLayout: IBlockContent = {
    editable: true,
    ...DefaultTableLayout([]),
  };

  if (visible) {
    editor.BlockManager.add(tableLayoutId, {
      category: 'Responsive',
      content: tableLayout,
      label: 'Table Layout',
      media:
        '<svg viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><path fill="currentColor" d="m 60,-860 v 331.52344 H 275.39062 V -860 Z m 624.60938,0 v 331.52344 H 900 V -860 Z m -579.21876,45.39062 H 230 v 240.78126 H 105.39062 Z m 628.4375,0 h 120.78126 v 240.78126 H 733.82812 Z M 372.30469,-431.52344 V -100 h 215.39062 v -331.52344 z m 45.39062,45.35156 h 124.60938 v 240.78126 H 417.69531 Z" /><path fill="currentColor"  d="M 59.551109,-99.765941 V -431.28938 H 274.94173 v 331.523439 z m 624.609381,0 V -431.28938 H 899.55111 V -99.765941 Z M 104.94173,-145.15656 H 229.55111 V -385.93782 H 104.94173 Z m 628.43748,0 h 120.7813 V -385.93782 H 733.37921 Z M 371.8558,-528.2425 v -331.52344 h 215.39062 v 331.52344 z m 45.39062,-45.35156 H 541.8558 V -814.37532 H 417.24642 Z" /></svg>',
      select: true,
    });
  }
};

export default TableLayoutPlugin;
