import { type FormEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  type IApiThrowsError,
  type IFrom,
  type ILocation,
  Logger,
} from '@gbs-monorepo-packages/common';

import { FormLayout } from '../../components/FormLayout';
import { strongPasswordValidRegex } from '../../constants/Auth';
import { type ISignIn } from '../../contexts';
import { useAuth } from '../../hooks/useAuth';
import {
  ErrorMessage,
  Fieldset,
  ForgotLink,
  Form,
  Input,
  Label,
  LazyImageCustom,
  LockButton,
  LockedIcon,
  SubmitButton,
  TextContent,
  Title,
  UnLockedIcon,
} from './styles';

interface IError {
  password?: string;
  other?: string;
}

export const Login = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<IError>({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const location: ILocation<IFrom> = useLocation();
  const { signIn } = useAuth();

  const handlerShowPassword = () => {
    setShow((show) => !show);
  };

  const handlerSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (password && !strongPasswordValidRegex.test(password)) {
      setError({
        password:
          'Password must contain at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and no whitespace.',
      });
      return;
    }

    if (loading) return;
    setLoading(true);
    setError({});

    const data: ISignIn = {
      email,
      password,
    };

    const to = location.state?.from;
    if (to) {
      data.redirect = {
        to,
        replace: true,
      };
      Logger.debug('will be redirected to', to);
    }

    signIn(data)
      .catch(({ response }: IApiThrowsError) => {
        if (!response) {
          setError({
            other: 'Unexpected error, please try again later',
          });
          return;
        }
        const status = response.data.error.code;

        if (status >= 500) {
          setError({
            other: 'Server error, please try again later',
          });
        } else {
          setError({
            other: response.data.error.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FormLayout dataCy="login-form-container">
      <TextContent data-cy="text-container">
        <LazyImageCustom
          src={'/logo.png'}
          alt="logo-image"
          dataCy={'image-logo'}
        />
        <Title data-cy="title-login">Login on your account</Title>
      </TextContent>
      <Form onSubmit={handlerSubmit} data-cy="login-form">
        <Fieldset filled={!!email}>
          <Input
            data-cy="input-email"
            defaultValue={email}
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
            type="email"
            maxLength={40}
            autoComplete="email"
          />
          <Label data-cy="label-email" htmlFor="email">
            Email address
          </Label>
        </Fieldset>
        <Fieldset filled={!!password} isInvalid={!!error.password}>
          <Input
            data-cy="input-password"
            defaultValue={password}
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
            type={show ? 'text' : 'password'}
            maxLength={20}
          />
          <Label data-cy="label-password" htmlFor="password">
            Password
          </Label>
          <LockButton
            data-cy="button-eye"
            onClick={handlerShowPassword}
            type="button"
          >
            {show ? (
              <LockedIcon data-cy="icon-locked" />
            ) : (
              <UnLockedIcon data-cy="icon-unlocked" />
            )}
          </LockButton>
        </Fieldset>
        <ErrorMessage data-cy="error-message-login">
          {error.password ?? error.other}
        </ErrorMessage>
        <ForgotLink to={'/forgot-password'} data-cy="button-forgotPassword">
          Forgot password?
        </ForgotLink>
        <SubmitButton dataCy="button-login" type="submit" loading={loading}>
          Log In
        </SubmitButton>
      </Form>
    </FormLayout>
  );
};
