import { produce } from 'immer';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { type ICheckBoxGroupProps } from '@gbs-monorepo-packages/common';

import { type IDragDropHandler } from '../../../../contexts/dragDrop';
import { useDragDrop } from '../../../../hooks/useDragDrop';
import { type IFont } from '../../../../pages/EditCourse/components/FontsModal';
import { filterByAttribute } from '../../../../utils/filters';
import {
  CardsContainer,
  CheckBoxCardContainer,
  Container,
  Divider,
  Fieldset,
  FontCardCustom,
  Input,
  LabelNoFonts,
} from './styles';

export interface ISelectTemplatesProps
  extends Omit<
    ICheckBoxGroupProps,
    'children' | 'defaultValue' | 'onValueChange' | 'type' | 'value'
  > {
  dataCy?: string;
  defaultValue: IFont[];
  name?: string;
  onValueChange?: Dispatch<SetStateAction<IFont[]>>;
  placeholder?: string;
  value: IFont[];
}

const DroppableId = 'fonts';

export const SelectFonts = ({
  defaultValue,
  onValueChange,
  value: dataSelected,
  ...props
}: ISelectTemplatesProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [localData, setLocalData] = useState<IFont[]>(defaultValue);
  const { addDragDropListener, removeDragDropListener } = useDragDrop();

  const handleCheck = useCallback(
    (newChecked: IFont) => {
      setLocalData(
        produce((draft) => {
          draft[newChecked.index].selected = true;
        })
      );
      onValueChange?.(
        produce((draft) => {
          draft.push(newChecked);
        })
      );
    },
    [onValueChange]
  );

  const handleUncheck = useCallback(
    (newChecked: IFont, index: number) => {
      setLocalData(
        produce((draft) => {
          draft[newChecked.index].selected = false;
        })
      );
      onValueChange?.(
        produce((draft) => {
          draft.splice(index, 1);
        })
      );
    },
    [onValueChange]
  );

  useEffect(() => {
    const handleValueChange: IDragDropHandler = ({ droppableId, from, to }) => {
      if (droppableId !== DroppableId) return;
      onValueChange?.(
        produce((draft) => {
          const [dragged] = draft.splice(from, 1);
          draft.splice(to, 0, dragged);
        })
      );
    };
    addDragDropListener(DroppableId, handleValueChange);

    return () => {
      removeDragDropListener(DroppableId);
    };
  }, [addDragDropListener, onValueChange, removeDragDropListener]);

  const dataFiltered = filterByAttribute(localData, 'family', search);
  const dataSelectedFiltered = filterByAttribute(
    dataSelected,
    'family',
    search
  );

  const showDivider = !!(
    dataSelected.length && dataFiltered.length - dataSelectedFiltered.length
  );

  return (
    <Container
      {...props}
      type="multiple"
      data-cy="select-template-container"
      defaultValue={dataSelected.map((data) => data.family)}
    >
      <Fieldset>
        <Input
          id="search-fonts"
          data-cy="input-searchFonts"
          value={search}
          onChange={(e) => {
            if (e.target.value.length <= 20) {
              setSearch(e.target.value);
            }
          }}
          placeholder="Search"
          ref={inputRef}
          type="text"
        />
      </Fieldset>

      <CardsContainer data-cy="scrollable-selected-fonts-container">
        <CheckBoxCardContainer>
          {dataSelected.map((data, index) => (
            <FontCardCustom
              key={data.index}
              data={data}
              onClick={() => {
                handleUncheck(data, index);
              }}
            ></FontCardCustom>
          ))}
        </CheckBoxCardContainer>
      </CardsContainer>

      {showDivider && <Divider />}

      <CardsContainer data-cy="fonts-container">
        <CheckBoxCardContainer>
          {dataFiltered.length ? (
            dataFiltered.map((data) =>
              data.selected ?? false ? null : (
                <FontCardCustom
                  data={data}
                  key={data.family}
                  onClick={() => {
                    handleCheck(data);
                  }}
                />
              )
            )
          ) : (
            <LabelNoFonts data-cy="text-noTemplates">
              No fonts found
            </LabelNoFonts>
          )}
        </CheckBoxCardContainer>
      </CardsContainer>
    </Container>
  );
};
