import { type ReactNode, forwardRef } from 'react';

import * as ToggleGroup from '@radix-ui/react-toggle-group';

import {
  CheckContainer,
  CheckIcon,
  CheckboxFake,
  Indicator,
  Item,
} from './styles';

export interface ICheckBoxCardProps extends ToggleGroup.ToggleGroupItemProps {
  children: ReactNode;
  dataCy?: string;
  isNotButton?: boolean;
}

export const CheckBoxCard = forwardRef<HTMLButtonElement, ICheckBoxCardProps>(
  // eslint-disable-next-line no-restricted-syntax
  function CheckBoxCard(
    { children, dataCy = 'checkbox-card', isNotButton = false, ...props },
    ref
  ): JSX.Element {
    const ItemAs = isNotButton ? 'div' : 'button';
    return (
      <Item {...props} data-cy={dataCy} ref={ref} asChild>
        <ItemAs>
          <CheckContainer data-cy="card-checkbox-button-container">
            <CheckboxFake data-cy="button-checkbox">
              <Indicator data-cy="card-checkbox-indicator">
                <CheckIcon />
              </Indicator>
            </CheckboxFake>
          </CheckContainer>
          {children}
        </ItemAs>
      </Item>
    );
  }
);

export interface ICheckBoxItemProps extends ToggleGroup.ToggleGroupItemProps {
  children: ReactNode;
  dataCy?: string;
}

export const CheckBoxItem = ({
  children,
  dataCy = 'checkbox-item',
  ...props
}: ICheckBoxItemProps): JSX.Element => {
  return (
    <Item {...props} data-cy={dataCy}>
      <CheckboxFake data-cy="checkbox-item-button-fake">
        <Indicator data-cy="checkbox-item-indicator">
          <CheckIcon />
        </Indicator>
      </CheckboxFake>
      {children}
    </Item>
  );
};

export interface ICheckBoxGroupProps
  extends ToggleGroup.ToggleGroupMultipleProps {
  children: ReactNode;
  dataCy?: string;
}

export const CheckBoxGroup = ({
  children,
  dataCy = 'checkbox-card-group',
  type = 'multiple',
  ...props
}: ICheckBoxGroupProps): JSX.Element => {
  return (
    <ToggleGroup.Root {...props} data-cy={dataCy} type={type}>
      {children}
    </ToggleGroup.Root>
  );
};
