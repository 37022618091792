export enum ETypeFolder {
  PUBLIC = 1,
  PRIVATE = 2,
  CLIENT = 3,
  RENEWAL = 4,
  EDGE = 5,
}

export const documentStatus = {
  approved: 'Approved',
  declined: 'Declined',
  waitingForApproval: 'Waiting For Approval',
};

export const folderTypeCategories = {
  [ETypeFolder.PUBLIC]: 'public',
  [ETypeFolder.EDGE]: 'public',
  [ETypeFolder.PRIVATE]: 'private',
  [ETypeFolder.CLIENT]: 'private',
  [ETypeFolder.RENEWAL]: 'private',
};
