import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  BaseDropdown,
  BaseTooltip,
  LazyImage,
  SearchBar,
  useToast,
} from '@gbs-monorepo-packages/common';

import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { useCompanies } from '../../hooks/useCompanies';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type ICompanyDTO,
  type IPaginationCompaniesDTO,
  getCompanies,
} from '../../services/companies';
import Logger from '../../utils/logger';
import {
  CompanyCard,
  CompanyCardGrid,
  CompanyName,
  Container,
  ContentCard,
  DropdownButtonContainer,
  DropdownItem,
  DropdownItemAsLink,
  GridContent,
  GridIcon,
  GridItem,
  GridListOptions,
  Header,
  HeaderContent,
  ImageContainer,
  LabelContent,
  ListIcon,
  Loading,
  LoadingContainer,
  MainContainer,
  Option,
  TitlePage,
} from './styles';

export const Dashboard = (): JSX.Element => {
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companiesAux, setCompaniesAux] = useState<ICompanyDTO[]>([]);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const { addToast } = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { selectedListOptionStyle, selectListOptionStyle } = useCompanies();

  const getCompaniesAux = useCallback(
    (page: number, limit: number) => {
      setLoadingCompanies(true);
      getCompanies({ page, limit, filter: search })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(response.data);
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    },
    [search, addToast]
  );

  const searchCompanies = (searchByButton = false) => {
    if (!loadingCompanies || searchByButton) {
      setLoadingCompanies(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCompanies({
        page: pageAux + 1,
        limit: 12,
        filter: search,
      })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setCompaniesAux([]);
        searchCompanies(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    if (!companiesAux.length) {
      setLoadingCompanies(true);
      getCompaniesAux(1, 12);
    }
  }, []);

  return (
    <Container data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-clients">Clients</TitlePage>
      </Header>

      <GridListOptions>
        <Option
          data-cy="option-list"
          color={selectedListOptionStyle === 'list' ? '#00426b' : '#414042'}
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'list' });
          }}
        >
          <ListIcon />
        </Option>
        <Option
          data-cy="option-grid"
          color={selectedListOptionStyle === 'grid' ? '#00426b' : '#414042'}
          onClick={() => {
            selectListOptionStyle({ optionStyle: 'grid' });
          }}
        >
          <GridIcon />
        </Option>
      </GridListOptions>
      <MainContainer data-cy="list">
        <SearchBar
          dataCy="input-searchClient"
          search={search}
          placeholder="Search client"
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingCompanies}
        />

        <ContentPagination dataCy="content-dashboard">
          {selectedListOptionStyle === 'list' ? (
            <HeaderContent data-cy="header-list">
              <LabelContent data-cy="column-clientName">
                Client Name
              </LabelContent>
            </HeaderContent>
          ) : (
            ''
          )}
          {loadingCompanies && companiesAux.length === 0 ? (
            <LoadingContainer data-cy="loading-clients-container">
              <Loading dataCy="loading-clients" />
            </LoadingContainer>
          ) : selectedListOptionStyle === 'list' && companiesAux.length > 0 ? (
            <InfiniteScroll
              data-cy="dashboard-infiniteScroll"
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length} // This is important field to render the next data
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-members-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {companiesAux.map((company) => (
                <CompanyCard
                  key={company.id}
                  to={`/companies/${company.id}/courses`}
                  data-cy={`client-card-${company.id}-container`}
                >
                  <CompanyName data-cy={`client-${company.id}-name`}>
                    {company.name}
                  </CompanyName>
                  <div style={{ display: 'flex' }}>
                    <DropdownButtonContainer data-cy="dropdown-container">
                      <BaseDropdown dataCy={`dropdown-${company.id}`}>
                        <DropdownItem
                          data-cy={`option-courses${company.id}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/courses`}
                          >
                            Courses
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`option-documents${company.id}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/folder/public/`}
                          >
                            Documents
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`option-users${company.id}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/members`}
                          >
                            Users
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`option-templates${company.id}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/templates`}
                          >
                            Templates
                          </DropdownItemAsLink>
                        </DropdownItem>
                        <DropdownItem
                          data-cy={`option-analytics${company.id}`}
                          asChild
                        >
                          <DropdownItemAsLink
                            to={`/companies/${company.id}/analytics`}
                          >
                            Analytics
                          </DropdownItemAsLink>
                        </DropdownItem>
                      </BaseDropdown>
                    </DropdownButtonContainer>
                  </div>
                </CompanyCard>
              ))}
            </InfiniteScroll>
          ) : selectedListOptionStyle === 'grid' && companiesAux.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length}
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              <GridContent>
                {companiesAux.map((company) => (
                  <GridItem key={company.id}>
                    <CompanyCardGrid
                      to={`/companies/${company.id}/courses`}
                      data-cy={`client-card-${company.id}-container`}
                    >
                      <ImageContainer data-cy={'container-imageClient'}>
                        <LazyImage
                          src={
                            company.clientFile === null
                              ? ''
                              : company.clientFile?.path
                          }
                          alt="company-image"
                          dataCy="image-client"
                        />
                      </ImageContainer>
                      <ContentCard>
                        <BaseTooltip message={company.name}>
                          <CompanyName data-cy={`client-${company.id}-name`}>
                            {company.name}
                          </CompanyName>
                        </BaseTooltip>
                        <DropdownButtonContainer data-cy="dropdown-container">
                          <BaseDropdown dataCy={`dropdown-${company.id}`}>
                            <DropdownItem
                              data-cy={`option-courses${company.id}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/courses`}
                              >
                                Courses
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`option-documents${company.id}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/folder/public/`}
                              >
                                Documents
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`option-users${company.id}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/members`}
                              >
                                Users
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`option-templates${company.id}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/templates`}
                              >
                                Templates
                              </DropdownItemAsLink>
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`option-analytics${company.id}`}
                              asChild
                            >
                              <DropdownItemAsLink
                                to={`/companies/${company.id}/analytics`}
                              >
                                Analytics
                              </DropdownItemAsLink>
                            </DropdownItem>
                          </BaseDropdown>
                        </DropdownButtonContainer>
                      </ContentCard>
                    </CompanyCardGrid>
                  </GridItem>
                ))}
              </GridContent>
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No clients found"
              dataCy="no-clients-found"
            />
          )}
        </ContentPagination>
      </MainContainer>
    </Container>
  );
};
