import { AxiosError } from 'axios';
import { type FormEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  type IApiThrowsError,
  type IRoutePath,
  getRouteFrom,
  useToast,
} from '@gbs-monorepo-packages/common';

import { FormLayout } from '../../components/FormLayout';
import { useAuth } from '../../hooks/useAuth';
import {
  Container,
  Description,
  EmailContainer,
  EmailIcon,
  ErrorMessage,
  Fieldset,
  Form,
  Input,
  Label,
  LazyImageCustom,
  LoginLink,
  LoginLinkContainer,
  ResendButton,
  SubmitButton,
  TextContent,
  Title,
} from './styles';

interface IForgotPasswordProps {
  authorizationRoute: IRoutePath;
}

export const ForgotPassword = ({
  authorizationRoute,
}: IForgotPasswordProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [emailSended, setEmailSended] = useState(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { addToast } = useToast();
  const isSendEmailFlow = !emailSended;

  const loadedErrorMessageOnce = useRef(false);
  const { forgotPassword } = useAuth();

  const handlerSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) return;
    setLoading(true);
    setError('');
    forgotPassword({
      email,
    })
      .then(() => {
        setEmailSended(true);
      })
      .catch((error: IApiThrowsError) => {
        if (!(error instanceof AxiosError)) {
          setError('Unexpected error, please try again later');
          return;
        }

        const { response } = error;
        if (!response) {
          setError('Unexpected error, please try again later');
          return;
        }
        const { status, data } = response;

        if (status >= 500) {
          setError('Server error, please try again later');
        } else {
          setError(data.error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const errorMessage = searchParams.get('errorMessage');

    if (errorMessage && !loadedErrorMessageOnce.current) {
      loadedErrorMessageOnce.current = true;
      addToast({
        title: 'Link expired',
        description: errorMessage,
        styleType: 'error',
        dataCy: 'reset-password-error-toast',
      });
    }
  }, []);

  return (
    <FormLayout dataCy="forgot-password-form-container">
      {isSendEmailFlow ? (
        <>
          <TextContent data-cy="text-container">
            <LazyImageCustom
              src={'/logo.png'}
              alt="logo-image"
              dataCy={'image-logo'}
            />
            <Title data-cy="title-recoverPassword">Recover your password</Title>
          </TextContent>
          <Form onSubmit={handlerSubmit} data-cy="forgot-password-form">
            <Fieldset filled={!!email}>
              <Input
                data-cy="input-email"
                defaultValue={email}
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                type="email"
                maxLength={40}
                autoComplete="email"
              />
              <Label data-cy="label-email" htmlFor="email">
                Email address
              </Label>
            </Fieldset>
            <ErrorMessage data-cy={error}>{error}</ErrorMessage>
            <SubmitButton
              type="submit"
              dataCy="button-recoverPassword"
              loading={loading}
            >
              Recover password
            </SubmitButton>
          </Form>
          <LoginLinkContainer data-cy="text-goBack">
            Go back to
            <LoginLink
              to={getRouteFrom(authorizationRoute)}
              data-cy="button-login"
            >
              Login
            </LoginLink>
          </LoginLinkContainer>
        </>
      ) : (
        <Container data-cy="success-content">
          <TextContent data-cy="text-container">
            <Title data-cy="title-checkInbox">Check your inbox!</Title>
            <EmailContainer data-cy="imagem-container">
              <EmailIcon />
            </EmailContainer>
            <Description data-cy="description-checkEmail">
              {`Please check your email ${email} for instructions on how you can reset your password.`}
            </Description>
          </TextContent>
          <ResendButton
            data-cy="button-resendEmail"
            onClick={() => {
              setEmailSended(false);
            }}
          >
            Resend email
          </ResendButton>
        </Container>
      )}
    </FormLayout>
  );
};
