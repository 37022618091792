import { useCallback, useState } from 'react';

import {
  DialogModal,
  type IDialogModalProps,
} from '../../../../components/DialogModal';
import { Fieldset, Input, Label } from './styles';

interface IRenamePageModalProps
  extends Partial<
    Omit<IDialogModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  onAccept: (newPageTitle: string) => void;
  onDecline: () => void;
  open: boolean;
  pageTitle: string;
  waitToOpen?: boolean;
}

export const RenamePageModal = ({
  onAccept,
  onDecline,
  open,
  pageTitle,
  waitToOpen = false,
  ...props
}: IRenamePageModalProps): JSX.Element | null => {
  const [newPageTitle, setNewPageTitle] = useState('');

  const handleRenamePage = useCallback(() => {
    const newTitle = newPageTitle.trim();
    if (!newTitle) {
      return;
    }

    onAccept?.(newTitle);

    setNewPageTitle('');
  }, [newPageTitle, onAccept]);

  const handleDeclineRenamePage = useCallback(() => {
    onDecline?.();
    setNewPageTitle('');
  }, [onDecline]);

  return !open ? null : (
    <DialogModal
      acceptText="Change"
      dataCy="title-renamePage"
      declineText="Cancel"
      mainText={`What is the new title of the page ${pageTitle}?`}
      {...props}
      onAccept={handleRenamePage}
      onDecline={handleDeclineRenamePage}
      onOpenChange={handleDeclineRenamePage}
      open={!waitToOpen}
      disabled={!newPageTitle.trim()}
    >
      <Fieldset data-cy="field-set" filled={!!newPageTitle}>
        <Input
          data-cy="input-pageName"
          value={newPageTitle}
          id="page-name"
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setNewPageTitle(e.target.value);
            }
          }}
          required
          type="text"
        />
        <Label data-cy="label-pageName" htmlFor="page-name">
          Title
        </Label>
      </Fieldset>
    </DialogModal>
  );
};
