import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';

import { Can, type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseModal,
  Button,
  type IRole,
  NotViewerUsers,
  SearchBar,
  SelectData,
  clientAndEmployeeWithLevel,
  rolesWithLevel,
  rolesWithoutAdminWithLevel,
  translateUserRoleInRole,
  useToast,
} from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { COMPANY_ID } from '../../constants/Env';
import { REDIRECT } from '../../constants/RoutePaths';
import { useCompanies } from '../../hooks/useCompanies';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type IPaginationUserDTO,
  deleteMember,
  getUsersByCompany,
  inviteUser,
  updateMember,
} from '../../services/users';
import { getRouteFrom } from '../../utils/getRoutes';
import Logger from '../../utils/logger';
import { ModalAddUsers } from './components/ModalAddUsers';
import { ModalUpdateUsers } from './components/ModalUpdateUsers';
import {
  ButtonContainer,
  Center,
  ContainerGrid,
  DropdownButtonContainer,
  DropdownItem,
  Header,
  HeaderGrid,
  ItemGrid,
  ItemGridCenter,
  ItemText,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
} from './styles';
import { type UsersCreateSchema, type UsersUpdateSchema } from './userSchema';

export interface ISelectClientsProps {
  onValueChange?: (value: string) => void;
}

interface IInviteError {
  firstNameInvite?: string;
  lastNameInvite?: string;
  emailInvite?: string;
  other?: string;
  phoneInvite?: string;
}

interface IUserWithCurrentRoles extends IUserDTO {
  currentRole?: IRole | null;
}

const AlmostRoles = NotViewerUsers;

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;
    // const userRoleAux = roles.find(({ key }) => key === value);
    // userRoleAux can be null
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

const MAIN_CLIENT_ID_NUM = Number(COMPANY_ID);

export const MembersCompany = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedCompany } = useCompanies();
  const { companyId = '' } = useParams(); // need to save this value to CLIENTS route

  const { user } = useAuth();
  const [loadingMembers, setLoadingMembers] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);

  const [users, setUsers] = useState<IUserWithCurrentRoles[]>([]);
  const [userToDelete, setUserToDelete] =
    useState<IUserWithCurrentRoles | null>(null);

  const [error, setError] = useState<IInviteError>({});

  const [firstNameEdit, setFirstNameEdit] = useState('');
  const [lastNameEdit, setLastNameEdit] = useState('');
  const [emailEdit, setEmailEdit] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [roleEdit, setRoleEdit] = useState('');

  const { addToast } = useToast();

  const isModalDeleteOpen = !!userToDelete;

  const [search, setSearch] = useState('');
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const lastSearch = useRef(search);

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  useEffect(() => {
    if (error.other) {
      addToast({
        title: 'Something went wrong',
        description: error.other,
        styleType: 'error',
        dataCy: 'error-toast',
      });
    }
  }, [addToast, error.other]);

  useEffect(() => {
    if (successRequest) {
      setTimeout(() => {
        setSuccessRequest(false);
      }, 2000);
    }
  }, [successRequest]);

  const rolesAux = useMemo(() => {
    if (currentRole !== null) {
      let roleAuxToFilter;

      if (Number(companyId) !== MAIN_CLIENT_ID_NUM) {
        roleAuxToFilter =
          selectedCompany?.isAgency === true
            ? rolesWithoutAdminWithLevel
            : clientAndEmployeeWithLevel;
      } else {
        roleAuxToFilter = rolesWithLevel;
      }

      return roleAuxToFilter.filter(
        ({ level }) => level <= (currentRole.level ?? 0)
      );
    } else {
      return [];
    }
  }, [currentRole, companyId, selectedCompany]);

  const handleDeclineDelete = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const handleAcceptDelete = useCallback(() => {
    if (userToDelete && isModalDeleteOpen) {
      setLoadingDelete(true);
      void deleteMember(userToDelete.id)
        .then(() => {
          getUsers(companyId, 1, 15);
        })
        .catch(() => {
          setLoadingDelete(false);
          setUserToDelete(null);
          setTimeout(() => {
            addToast({
              title: 'Something went wrong',
              description: 'An error occurred while deleting the user',
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 1000);
        });
    }
  }, [userToDelete, isModalDeleteOpen, users]);

  const handleRoleSelectChange = (
    value: string,
    email: string,
    firstName: string,
    lastName: string,
    phone?: string
  ) => {
    setDialogLoadingOpen(true);

    void updateMember({
      firstName,
      lastName,
      email,
      roles: Array(value),
      phone,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })

      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          if (error.response && error.response.status >= 500) {
            setError({
              other: 'Server error, please try again later',
            });
          } else {
            setError({
              other: error.response?.data.error.message,
            });
          }
        }, 1000);
      })
      .finally(() => {
        setDialogLoadingOpen(false);
      });
  };

  const inviteMemberSubmit = (data: UsersCreateSchema) => {
    setLoadingInvite(true);
    void inviteUser({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: [data.roles],
      clientId: Number(companyId),
      phone: data.phone,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        setDialogOpen(false);
        setSuccessRequest(true);
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          let errorMessage = '';
          if (error.response && error.response.status >= 500) {
            errorMessage =
              'An error occurred. Please try again or contact Benefit Education support.';
          } else {
            errorMessage = error.response?.data.error.message ?? '';
          }
          addToast({
            title: 'Something went wrong',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  const getUsers = (id: string, page: number, limit: number) => {
    setLoadingMembers(true);
    setLoadingEdit(true);
    getUsersByCompany(id, page, limit, search)
      .then((response: IPaginationUserDTO) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].currentRole = getCurrentRole(response.data[i].roles);
        }

        setUsers(response.data);
        setPaginationMeta(response.meta);

        if (dialogOpen) {
          addToast({
            title: 'Success',
            description: 'User has been added!',
            styleType: 'success',
            dataCy: 'success-toast',
          });
        }

        if (isModalDeleteOpen) {
          setLoadingDelete(false);
          setUserToDelete(null);
          addToast({
            title: 'Success',
            description: 'User deleted!',
            styleType: 'success',
            dataCy: 'success-toast',
          });
        }
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
      })
      .finally(() => {
        setLoadingMembers(false);
        setLoadingEdit(false);
      });
  };

  const handlerEditSubmit = (data: UsersUpdateSchema) => {
    void updateMember({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roles: [],
      phone: data.phone,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        setDialogEditOpen(false);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
        setTimeout(() => {
          addToast({
            title: 'Something went wrong',
            description: 'An error occurred while updating the user',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  };

  const handleOpenModalEdit = (data: IUserWithCurrentRoles) => {
    setEmailEdit(data.email);
    setFirstNameEdit(data.firstName);
    setLastNameEdit(data.lastName);
    setPhoneEdit(data.phone ?? '');
    setRoleEdit(data.currentRole?.key ?? '');
    setDialogEditOpen(true);
  };

  const handleOpenModalDelete = (data: IUserWithCurrentRoles) => {
    setUserToDelete(data);
  };

  const searchUsers = (searchByButton = false) => {
    if (!loadingMembers || searchByButton) {
      setLoadingMembers(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getUsersByCompany(companyId, pageAux + 1, 15, search)
        .then((response: IPaginationUserDTO) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].currentRole = getCurrentRole(
              response.data[i].roles
            );
          }

          setUsers(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setUsers([]);
        searchUsers(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const intCompanyId = parseInt(companyId);

    if (Number.isInteger(intCompanyId) && intCompanyId > 0) {
      getUsers(intCompanyId.toString(), 1, 15);
    } else {
      navigate(getRouteFrom(REDIRECT));
    }
  }, [companyId, navigate]);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-users">Users</TitlePage>
        <Can roles={AlmostRoles}>
          <ButtonContainer>
            <Button
              dataCy="button-addUser"
              onClick={() => {
                setDialogOpen(true);
              }}
              type="button"
              disabled={loadingInvite || loadingMembers || loadingEdit}
            >
              Add User
            </Button>
          </ButtonContainer>
        </Can>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          search={search}
          dataCy="input-searchUser"
          placeholder="Search user"
          onChange={(e) => {
            if (e.target.value.length <= 60) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingMembers}
        />

        <ContentPagination dataCy="content-members">
          <HeaderGrid>
            <ItemGrid data-cy="item-grid">
              <p data-cy="column-user">User Name</p>
            </ItemGrid>

            <Can data-cy={AlmostRoles} roles={AlmostRoles}>
              <ItemGrid data-cy="item-grid">
                <p data-cy="column-userType">User Type</p>
              </ItemGrid>
            </Can>
          </HeaderGrid>

          {loadingMembers && users.length === 0 ? (
            <LoadingContainer data-cy="loading-users-container">
              <Loading />
            </LoadingContainer>
          ) : users.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={users.length} // This is important field to render the next data
              next={searchUsers}
              hasMore={users.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {users.map((member) => (
                <ContainerGrid
                  data-cy={`users-card-${member.id}-container`}
                  key={member.id}
                >
                  <ItemGrid data-cy="item-grid">
                    <ItemText data-cy={`users-${member.id}-name`}>
                      {member.firstName} {member.lastName}
                    </ItemText>
                    <ItemText data-cy={`users-${member.id}-email`}>
                      {member.email}
                    </ItemText>
                  </ItemGrid>
                  <Can
                    data-cy={`users-${member.id}-userType`}
                    roles={AlmostRoles}
                  >
                    {(currentRole?.level ?? 0) >=
                      (member.currentRole?.level ?? 0) &&
                    member.id !== user?.id ? (
                      <>
                        <ItemGridCenter data-cy="item-grid">
                          <SelectData
                            data={rolesAux}
                            dataCy="select-userType"
                            name="select-userType"
                            onValueChange={(e) => {
                              handleRoleSelectChange(
                                e,
                                member.email,
                                member.firstName,
                                member.lastName,
                                member.phone
                              );
                            }}
                            defaultValue={member.roles[0]}
                          />
                        </ItemGridCenter>
                        <ItemGridCenter data-cy="item-grid">
                          <DropdownButtonContainer data-cy="dropdown-container">
                            <BaseDropdown
                              dataCy={`dropdown-user`}
                              onOpenChange={(isOpen) => {
                                setIsDropdownOpen(isOpen);
                              }}
                            >
                              <DropdownItem
                                data-cy={`option-edit`}
                                onClick={() => {
                                  handleOpenModalEdit(member);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                data-cy={`option-delete`}
                                onClick={() => {
                                  handleOpenModalDelete(member);
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </BaseDropdown>
                          </DropdownButtonContainer>
                        </ItemGridCenter>
                      </>
                    ) : (
                      <ItemGrid data-cy="item-grid">
                        <ItemText data-cy={member.currentRole?.value}>
                          {member.currentRole?.value}
                        </ItemText>
                      </ItemGrid>
                    )}
                  </Can>
                </ContainerGrid>
              ))}
            </InfiniteScroll>
          ) : (
            <CenteredText message="No users found" dataCy="no-users-found" />
          )}
        </ContentPagination>
      </MainContent>

      <BaseModal
        onOpenChange={setDialogLoadingOpen}
        open={dialogLoadingOpen}
        dataCy="spinner-loading-content-modal"
        hiddenCloseButton={true}
      >
        <Center>
          <Loading src={LoadingSpinnerAnimated} />
        </Center>
      </BaseModal>

      <DialogModal
        dataCy="title-deleteUser"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDeleteOpen}
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          userToDelete?.firstName ?? 'this user'
        } ${userToDelete?.lastName ?? ''}?`}
        onAccept={handleAcceptDelete}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
      />

      {selectedCompany && (
        <ModalAddUsers
          onAccept={inviteMemberSubmit}
          onDecline={() => {
            setDialogOpen(false);
          }}
          open={dialogOpen}
          loading={loadingInvite}
          success={successRequest}
          company={selectedCompany}
        />
      )}
      <ModalUpdateUsers
        onAccept={handlerEditSubmit}
        onDecline={() => {
          setDialogEditOpen(false);
        }}
        open={!isDropdownOpen && dialogEditOpen && emailEdit !== ''}
        loading={loadingEdit}
        email={emailEdit}
        firstName={firstNameEdit}
        lastName={lastNameEdit}
        phone={phoneEdit}
        currentRole={roleEdit}
      />
    </MainContainer>
  );
};
