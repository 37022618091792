import { useAuth } from '@gbs-monorepo-packages/auth';
import * as Accordion from '@radix-ui/react-accordion';

import {
  type ITemplateGroupDTO,
  type ITemplatePageDTO,
} from '../../../../services/templates';
import {
  Loading,
  LoadingContainer,
  NoTemplatesContainer,
  NoTemplatesText,
} from '../../styles';
import { TemplateCard } from '../TemplateCard';
import {
  AccordionContent,
  AccordionGroupActions,
  AccordionGroupLabel,
  AccordionTrigger,
  ActionButton,
  DeleteIcon,
  EditIcon,
  TemplateCardContainer,
} from './styles';

export interface IGroupTemplateCardProps {
  groupTemplate: ITemplateGroupDTO;
  templates: ITemplatePageDTO[] | null;

  onEditGroupClick?: (groupTemplate: ITemplateGroupDTO) => void;
  onDeleteGroupClick?: (groupTemplate: ITemplateGroupDTO) => void;
  onEditTemplateClick?: (
    groupTemplate: ITemplateGroupDTO,
    template: ITemplatePageDTO
  ) => void;
  onDeleTeTemplateClick?: (
    groupTemplate: ITemplateGroupDTO,
    template: ITemplatePageDTO
  ) => void;
}

export const GroupTemplateCard = ({
  groupTemplate,
  templates,
  onEditGroupClick,
  onDeleteGroupClick,
  onEditTemplateClick,
  onDeleTeTemplateClick,
}: IGroupTemplateCardProps): JSX.Element => {
  const { user } = useAuth();

  return (
    <Accordion.Item
      data-cy={`courseTemplate-${groupTemplate.id.toString()}-accordion-item`}
      value={groupTemplate.id.toString()}
    >
      <AccordionTrigger
        data-cy={`courseTemplate-${groupTemplate.id}-accordion-trigger`}
      >
        <AccordionGroupLabel
          data-cy={`courseTemplate-${groupTemplate.id}-name`}
        >
          {groupTemplate.title}
        </AccordionGroupLabel>

        <AccordionGroupActions
          data-cy={`courseTemplate-card-${groupTemplate.id}-container`}
        >
          {onEditGroupClick && (
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();

                onEditGroupClick(groupTemplate);
              }}
              data-cy="button-update"
              title="Update"
              isVisible={groupTemplate.userCreatedId === user?.id}
            >
              <EditIcon />
            </ActionButton>
          )}
          {onDeleteGroupClick && (
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();

                onDeleteGroupClick(groupTemplate);
              }}
              data-cy="button-delete"
              title="Delete"
              isVisible={groupTemplate.userCreatedId === user?.id}
            >
              <DeleteIcon />
            </ActionButton>
          )}
        </AccordionGroupActions>
      </AccordionTrigger>

      <AccordionContent
        data-cy={`courseTemplate-${groupTemplate.id.toString()}-accordion-content`}
      >
        {templates === null && (
          <LoadingContainer data-cy="loading-templates-container">
            <Loading dataCy="loading-templates-label" />
          </LoadingContainer>
        )}

        {templates && templates.length === 0 && (
          <NoTemplatesContainer data-cy="no-templates-container">
            <NoTemplatesText data-cy="no-templates-found">
              No templates found
            </NoTemplatesText>
          </NoTemplatesContainer>
        )}

        {templates &&
          templates.length > 0 &&
          templates.map((template) => {
            return (
              <TemplateCardContainer
                key={`group-template-template-${template.id}-card`}
                data-cy={`courseTemplate-template-${template.id}-card`}
              >
                <TemplateCard
                  templateData={template}
                  onEditClick={(template) =>
                    onEditTemplateClick?.(groupTemplate, template)
                  }
                  onDeleteClick={(template) =>
                    onDeleTeTemplateClick?.(groupTemplate, template)
                  }
                  iconSize={'small'}
                />
              </TemplateCardContainer>
            );
          })}
      </AccordionContent>
    </Accordion.Item>
  );
};
