import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SearchBar, useToast } from '@gbs-monorepo-packages/common';

import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import type { IApiThrowsError, IPaginationMetaProps } from '../../services/api';
import {
  type ICompanyDTO,
  type IPaginationCompaniesDTO,
  getCompanies,
} from '../../services/companies';
import Logger from '../../utils/logger';
import {
  ButtonUrl,
  ButtonsContent,
  CompanyCard,
  CompanyName,
  Header,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
} from './styles';

export const Companies = (): JSX.Element => {
  const { addToast } = useToast();
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const [companiesAux, setCompaniesAux] = useState<ICompanyDTO[]>([]);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const getCompaniesAux = useCallback(
    (page: number, limit: number) => {
      setLoadingCompanies(true);
      getCompanies({ page, limit, filter: search })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(response.data);
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    },
    [search, addToast]
  );

  const searchCompanies = (searchByButton = false) => {
    if (!loadingCompanies || searchByButton) {
      setLoadingCompanies(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCompanies({
        page: pageAux + 1,
        limit: 12,
        filter: search,
      })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setCompaniesAux([]);
        searchCompanies(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    if (!companiesAux.length) {
      setLoadingCompanies(true);
      getCompaniesAux(1, 12);
    }
  }, []);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Clients</TitlePage>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          search={search}
          placeholder="Search client"
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingCompanies}
        />

        <ContentPagination dataCy="content-companies">
          {loadingCompanies && companiesAux.length === 0 ? (
            <LoadingContainer data-cy="loading-companies-container">
              <Loading dataCy="loading-companies" />
            </LoadingContainer>
          ) : companiesAux.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length} // This is important field to render the next data
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {companiesAux.map((company) => (
                <CompanyCard key={company.id} data-cy={company.id}>
                  <CompanyName data-cy={company.name}>
                    {company.name}
                  </CompanyName>
                  <ButtonsContent data-cy="info-container">
                    <ButtonUrl
                      data-cy={`buttonCourses-client${company.id}`}
                      to={`/companies/${company.id}/courses`}
                    >
                      Courses
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonDocuments-client${company.id}`}
                      to={`/companies/${company.id}/folder/public/`}
                    >
                      Documents
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonUsers-client${company.id}`}
                      to={`/companies/${company.id}/members`}
                    >
                      Users
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonTemplates-client${company.id}`}
                      to={`/companies/${company.id}/templates`}
                    >
                      Templates
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonAnalytics-client${company.id}`}
                      to={`/companies/${company.id}/analytics`}
                    >
                      Analytics
                    </ButtonUrl>
                  </ButtonsContent>
                </CompanyCard>
              ))}
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No clients found"
              dataCy="text-noClients-found"
            />
          )}
        </ContentPagination>
      </MainContent>
    </MainContainer>
  );
};
