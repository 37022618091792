import { isAxiosError } from 'axios';

import { type IApiThrowsError } from '../services';

export const UnexpectedError =
  'Unexpected error occurred. Please try again later.';

export const ServerError = 'Server error occurred. Please try again later.';

export const DefaultDescription =
  'An error occurred. Please try again or contact Platform support.';

export const DefaultDescriptionBenefitEducation =
  'An error occurred. Please try again or contact Benefit Education support.';

export const formatUnknownErrorMessage = (error: unknown): string => {
  let description = UnexpectedError;
  if (!isAxiosError(error)) {
    if (error instanceof Error) {
      description = error.message ?? UnexpectedError;
    }
  } else {
    const { response } = error as IApiThrowsError;
    if (response) {
      const { status, data } = response;

      if (status >= 500) {
        description = ServerError;
      } else {
        description =
          (data.error.message || data.error.title) ?? DefaultDescription;
      }
    }
  }
  return description;
};
